import axios from "axios";
import { showPersistentToast } from "../utils/persistentToast";
const api = axios.create({
  baseURL: "",
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      showPersistentToast('Your session has timed out. You have been logged out.', {
        position: "top-right",
        autoClose: false,
        closeOnClick: true,
        draggable: false,
        pauseOnHover: true,
        closeButton: true,
      });

      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default api;
