import React from "react";
import config from "../../config";

const LoginErrorPage = ({ errorCode }) => {
  let displayMessage;

  switch (errorCode) {
    case 401:
      displayMessage = (
        <span>
          Your account credentials could not be verified. Please verify that you
          are using your company account or request help through{" "}
          <a href={config.SLACK_URL} target="_blank" rel="noreferrer">
            #working-with-ai{" "}
          </a>
        </span>
      );
    case 404:
      displayMessage = (
        <span>
          It looks like you are not yet authorized to use the application. AI
          Sandbox is in early access. You may request access{" "}
          <a href={config.REQUEST_FORM_URL} target="_blank" rel="noreferrer">
            here
          </a>
        </span>
      );
    default:
      displayMessage = (
        <span>An error occurred during the login process: {errorCode}</span>
      );
  }

  return (
    <div id="introsection">
      <h1>Login Error</h1>
      <h2>{displayMessage}</h2>
    </div>
  );
};

export default LoginErrorPage;
