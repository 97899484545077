import React, { useEffect, useRef, useState } from "react";
import IntroSection from "../components/IntroSection";

import NavContent from "../components/NavContent";
import useAutosizeTextArea from "../hooks/useAutosizeTextArea";

import { ChatLogRow } from "../components/chatLogRow.react";
import { FooterMessage } from "../components/footerMessage.react/footerMessage";
import Avatar from "../components/Avatar";
import BotResponse from "../components/BotResponse";
import Loading from "../components/Loading";

import config from "../config";
import api from "../services/api";

import { reportError } from "../utils/rollbar";

const Home = ({ currentUser, setCurrentUser }) => {
  // UI State
  const [showMenu, setShowMenu] = useState(false);
  const [activeSessionId, setActiveSessionId] = useState("");

  // Chat Info & Data
  const [currentChatSessionId, setCurrentChatSessionId] = useState("");
  const [currentChatSessionName, setCurrentChatSessionName] = useState("");
  const [chatSessionNames, setChatSessionNames] = useState([]);
  const [chatLog, setChatLog] = useState([]);

  // Input & Feedback State
  const [inputPrompt, setInputPrompt] = useState("");
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [hasPrinted, setHasPrinted] = useState(false);

  const [warningVariant, setWarningVariant] = useState("");
  const [mostRecentWarningVariant, setMostRecentWarningVariant] = useState("");
  const [llmId, setLlmId] = useState(config.DEFAULT_FUNCTION_ID);
  const [llmLabel, setLlmLabel] = useState(config.DEFAULT_FUNCTION_LABEL);
  const [llmConfig, setLlmConfig] = useState(config.DEFAULT_LLM_CONFIG);

  const [form, setForm] = useState(null);

  const [modes, setModes] = useState({});

  // Refs
  const chatLogRef = useRef(null);
  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, inputPrompt);

  // Configs
  const CHAT_ENDPOINT = config.VERSION_ENDPOINT + "chat";
  const CHAT_SESSIONS_ENDPOINT = config.VERSION_ENDPOINT + "chat-session";
  const GENERATE_NAME_ENDPOINT =
    config.VERSION_ENDPOINT + "chat-session/generate-name";

  const removeMostRecentPrompt = () => {
    const newChatLog = [...chatLog];
    newChatLog.pop(); // Remove the last item
    setChatLog(newChatLog);
  };

  const handleKeyDown = (e) => {
    // Check if the Enter key is pressed without any modifiers
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      !e.altKey &&
      !(e.ctrlKey || e.metaKey)
    ) {
      e.preventDefault();
      handleSubmit(e);
    }
    // Check if Command + Enter or Control + Enter is pressed
    else if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      const newValue =
        inputPrompt.substring(0, start) + "\n" + inputPrompt.substring(end);
      setInputPrompt(newValue);
      // This ensures the caret position stays correct after adding the newline
      setTimeout(() => {
        e.target.selectionStart = start + 1;
        e.target.selectionEnd = start + 1;
      });
    }
  };

  const handleSubmit = (e) => {
    // This is needed to prevent the form from submitting and refreshing the page
    // when the submit button inside of the form is clicked
    e.preventDefault();

    if (!llmId) {
      return null;
    }
    if (llmId === "mini-rag" && form === null && chatLog.length === 0) {
      alert("You must upload a file to use this mode");
      return null;
    }

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        // Set responseFromAPI to true before making the fetch request
        setReponseFromAPI(true);
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        callAPI();

        // hide the keyboard in mobile devices
        e.target.blur();
      }

      async function callAPI() {
        try {
          let response;

          if (chatLog.length > 0) {
            const request = buildRequest(currentChatSessionId);
            response = await api.post(CHAT_ENDPOINT, request);
          } else {
            // Create new Chat Session document
            const newChatSession = await api.post(CHAT_SESSIONS_ENDPOINT);
            setCurrentChatSessionId(newChatSession.data["id"]);

            if (llmId === "mini-rag") {
              response = await api.put(
                CHAT_SESSIONS_ENDPOINT +
                  "/" +
                  newChatSession.data["id"] +
                  "/update-context",
                form,
                {
                  headers: {
                    "Content-Type": "multipart/form-data", // Need to include this to upload file
                  },
                }
              );
            } else {
              // Make new Chat document
              const request = buildRequest(newChatSession.data["id"]);
              response = await api.post(CHAT_ENDPOINT, request);
            }

            let status = response.data.status;
            if (
              status === "llm-service-error" ||
              status === "pdf-parse-error"
            ) {
              const error = new Error("LLM service provider unavailable");
              error.status = response.data.status;
              throw error;
            }

            const sessionName = await api.post(GENERATE_NAME_ENDPOINT, {
              question: inputPrompt,
              answer: response.data.observation,
            });

            setChatSessionNames([
              ...chatSessionNames,
              {
                name: sessionName.data,
                id: newChatSession.data["id"],
                llmId: llmId,
                llmLabel: llmLabel,
                llmConfig: llmConfig,
                time_created: newChatSession.data["time_created"],
              },
            ]);

            await api.put(
              CHAT_SESSIONS_ENDPOINT + "/" + newChatSession.data["id"],
              {
                session_name: sessionName.data,
                function_id: llmId,
                llm_config: llmConfig,
                llm_label: llmLabel,
              }
            );
          }

          const data = response.data;
          const observation = response.data.observation;
          const status = response.data.status;

          setChatLog([
            ...chatLog,
            {
              chatPrompt: inputPrompt,
              botMessage: observation,
              id: data.id,
              llmId: llmId,
              llmLabel: llmLabel,
              rating: data.rating,
              unhelpful: data.unhelpful,
              incorrect: data.incorrect,
              harmful: data.harmful,
              comment: data.comment,
              sources: data.sources,
            },
          ]);

          if (
            mostRecentWarningVariant !== status ||
            status === "token-limit-exceeded"
          ) {
            setWarningVariant(status);
          }
        } catch (error) {
          console.log(error);
          let status =
            error.status || error?.response?.data?.status || "generic-error";
          if (error.response?.status === 401) {
            status = "cookies-expired";
          }
          setInputPrompt(inputPrompt);
          textAreaRef.current.focus();
          setErrorOccurred(true);
          setWarningVariant(status);

          // Report the error to Rollbar
          reportError("Error in AI Sandbox", error);
        }

        //  Set responseFromAPI back to false after the fetch request is complete
        setReponseFromAPI(false);
      }
    }

    setInputPrompt("");
    textAreaRef.current.focus();
  };

  Home.handleSubmit = handleSubmit;

  function hasCustomLLMConfig() {
    let functionIds = [
      config.QUERY_OPENAI_LLM_FUNCTION_ID,
      config.QUERY_COHERE_LLM_FUNCTION_ID,
    ];
    return functionIds.includes(llmId);
  }

  function buildRequest(chatSessionId) {
    let context = { prompt: inputPrompt };
    let params = { context };

    if (hasCustomLLMConfig()) {
      context["messages"] = buildChatRequest(chatLog, inputPrompt);
      params["llm-config"] = llmConfig;
    } else {
      context["question"] = inputPrompt;
    }

    return {
      function_id: llmId,
      "chat-session-id": chatSessionId,
      params,
    };
  }

  function buildChatRequest(chatLog, recentPrompt) {
    const mappedMessages = chatLog.map((item) => {
      return [
        {
          role: "user",
          content: item.chatPrompt,
        },
        {
          role: "assistant",
          content: item.botMessage,
        },
      ];
    });

    const flatMessages = mappedMessages.flat();

    flatMessages.push({
      role: "user",
      content: recentPrompt,
    });

    return flatMessages;
  }

  const getChatSessions = async () => {
    api
      .get(CHAT_SESSIONS_ENDPOINT)
      .then((response) => {
        const newChatSessions = response.data.map((resp) => {
          return {
            name:
              resp["session_name"] === ""
                ? "Blank Chat Name"
                : resp["session_name"],
            id: resp["id"],
            llmId: resp["function_id"],
            llmLabel: resp["llm_label"],
            llmConfig: resp["llm_config"],
            time_created: resp["time_created"],
          };
        });
        setChatSessionNames(newChatSessions);
      })
      .catch((error) => {
        reportError("Error in AI Sandbox: Get chat sessions", error);
      });
  };

  useEffect(() => {
    if (chatSessionNames.length === 0) {
      getChatSessions();
    }
  }, [modes]);

  const setNewLlmValue = (newLlmValue) => {
    setLlmId(newLlmValue.value);
    setLlmLabel(newLlmValue.label);
  };

  useEffect(() => {
    if (errorOccurred) {
      removeMostRecentPrompt();
      setErrorOccurred(false);
      if (chatLog.length === 1) {
        api.delete(CHAT_SESSIONS_ENDPOINT + "/" + currentChatSessionId);
      }
    }
  }, [errorOccurred]);

  useEffect(() => {
    if (!activeSessionId) return;
    const thisChat = chatSessionNames.find((x) => x.id === activeSessionId);
    setLlmId(thisChat?.llmId ? thisChat.llmId : config.DEFAULT_FUNCTION_ID);
    setLlmLabel(
      thisChat?.llmLabel ? thisChat.llmLabel : config.DEFAULT_FUNCTION_LABEL
    );
  }, [activeSessionId]);

  const scrollToBottom = () => {
    chatLogRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 400);
  }, [chatLog]);

  return (
    <>
      <header>
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>AI Sandbox</h1>
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatNames={chatSessionNames}
              setChatNames={setChatSessionNames}
              setChatLog={setChatLog}
              setChatID={setCurrentChatSessionId}
              setShowMenu={setShowMenu}
              setHasPrinted={setHasPrinted}
              setChatName={setCurrentChatSessionName}
              setCurrentUser={setCurrentUser}
              activeId={activeSessionId}
              setActiveId={setActiveSessionId}
              textAreaRef={textAreaRef}
              setLlmLabel={setLlmLabel}
              setLlmId={setLlmId}
              setLlmConfig={setLlmConfig}
              setForm={setForm}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent
          chatNames={chatSessionNames}
          setChatNames={setChatSessionNames}
          setChatLog={setChatLog}
          setChatID={setCurrentChatSessionId}
          setShowMenu={setShowMenu}
          setHasPrinted={setHasPrinted}
          setChatName={setCurrentChatSessionName}
          setCurrentUser={setCurrentUser}
          activeId={activeSessionId}
          setActiveId={setActiveSessionId}
          textAreaRef={textAreaRef}
          setLlmLabel={setLlmLabel}
          setLlmId={setLlmId}
          setLlmConfig={setLlmConfig}
          setForm={setForm}
        />
      </aside>

      <section className="chatBox">
        {chatLog.length > 0 ? (
          <div className="chatLogWrapper">
            {chatLog.length > 0 &&
              chatLog.map((chat, idx) => (
                <ChatLogRow
                  chat={chat}
                  chatId={chat.id}
                  llmId={llmId}
                  chatLogRef={chatLogRef}
                  currentUser={currentUser}
                  hasPrinted={hasPrinted}
                  id={`navPrompt-${chat.chatPrompt.replace(
                    /[^a-zA-Z0-9]/g,
                    "-"
                  )}`}
                  index={idx}
                  key={idx}
                  llmLabel={llmLabel ? llmLabel : config.DEFAULT_FUNCTION_LABEL}
                  setHasPrinted={setHasPrinted}
                  rating={chat.rating}
                  unhelpful={chat.unhelpful}
                  incorrect={chat.incorrect}
                  harmful={chat.harmful}
                  comment={chat.comment}
                >
                  <div className="chatPromptMainContainer">
                    <div className="chatPromptWrapper">
                      <Avatar bg="#5437DB" className="userSVG">
                        <img
                          src={currentUser["picture"]}
                          alt="Tech Titan Pudgy"
                          style={{
                            height: "75px",
                            width: "70px",
                            position: "relative",
                            borderRadius: 150 / 2,
                            overflow: "hidden",
                            borderWidth: 3,
                          }}
                        />
                      </Avatar>
                      <div id="chatPrompt">{chat.chatPrompt}</div>
                    </div>
                  </div>

                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                      <Avatar bg="#11a27f" className="openaiSVG">
                        <img
                          src="/images/bamboohrlogo.png"
                          alt="BambooHR logo"
                          style={{
                            height: "85px",
                            width: "85px",
                            position: "relative",
                          }}
                        />
                      </Avatar>
                      {chat.botMessage ? (
                        <div id="botMessage">
                          <BotResponse
                            response={chat.botMessage}
                            chatLogRef={chatLogRef}
                            hasPrinted={hasPrinted}
                            setHasPrinted={setHasPrinted}
                            sources={chat.sources}
                          />
                        </div>
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </div>
                </ChatLogRow>
              ))}
          </div>
        ) : (
          <IntroSection
            setNewLlmValue={setNewLlmValue}
            setLlmModel={setLlmConfig}
            setLlmConfig={setLlmConfig}
            setModes={setModes}
            setForm={setForm}
            setInputPrompt={setInputPrompt}
            llmId={llmId}
          />
        )}
        <div className="inputPromptContainer">
          {
            <FooterMessage
              variant={warningVariant}
              hideAlert={() => {
                setMostRecentWarningVariant(warningVariant);
                setWarningVariant("");
              }}
            />
          }
          <form className="inputForm" onSubmit={handleSubmit}>
            <div className="inputPromptWrapper">
              <textarea
                name="inputPrompt"
                ref={textAreaRef}
                id=""
                className="inputPromptTextarea"
                type="text"
                rows={1}
                value={inputPrompt}
                onChange={(e) => setInputPrompt(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              ></textarea>
              {llmId ? (
                <button aria-label="form submit" type="submit">
                  <svg
                    fill="#ADACBF"
                    width={15}
                    height={20}
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#212023"
                    strokeWidth={0}
                  >
                    <title>{"submit form"}</title>
                    <path
                      d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                      stroke="none"
                    />
                  </svg>
                </button>
              ) : (
                <strong
                  style={{
                    margin: "4px 4px 4px 0",
                  }}
                >
                  CHOOSE AN LLM
                </strong>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Home;
