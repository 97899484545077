import React, { useEffect, useState } from "react";
import NavLinksContainer from "./NavLinksContainer";
import NavPrompt from "./NavPrompt";
import NewChat from "./NewChat";

const NavContent = ({
  setChatLog,
  chatNames,
  setChatNames,
  setChatID,
  setShowMenu,
  setHasPrinted,
  setChatName,
  setCurrentUser,
  activeId,
  setActiveId,
  textAreaRef,
  setLlmLabel,
  setLlmId,
  setLlmConfig,
  setForm,
}) => {
  const [todaySessions, setTodaySessions] = useState([]);
  const [last7DaysSessions, setLast7DaysSessions] = useState([]);
  const [last30DaysSessions, setLast30DaysSessions] = useState([]);
  const [olderSessions, setOlderSessions] = useState([]);

  // Helper function to sort and categorize chat sessions by time_create property
  const categorizeChatSessions = (sessions) => {
    // convert time_created to Date object
    sessions.forEach((session) => {
      session.time_created = new Date(session.time_created);
    });

    // Sort sessions by time_created in descending order
    sessions.sort((a, b) => {
      return b.time_created - a.time_created;
    });

    // Starting a day at midnight is intuitive for this use case
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const todaySessions = [];
    const last7DaysSessions = [];
    const last30DaysSessions = [];
    const olderSessions = [];

    sessions.forEach((session) => {
      const sessionDate = session.time_created;
      if (sessionDate.toDateString() === today.toDateString()) {
        todaySessions.push(session);
      } else if (sessionDate >= sevenDaysAgo && sessionDate < today) {
        last7DaysSessions.push(session);
      } else if (sessionDate >= thirtyDaysAgo) {
        last30DaysSessions.push(session);
      } else {
        olderSessions.push(session);
      }
    });

    return {
      todaySessions,
      last7DaysSessions,
      last30DaysSessions,
      olderSessions,
    };
  };

  useEffect(() => {
    const categorizedSessions = categorizeChatSessions([...chatNames]);

    setTodaySessions(categorizedSessions.todaySessions);
    setLast7DaysSessions(categorizedSessions.last7DaysSessions);
    setLast30DaysSessions(categorizedSessions.last30DaysSessions);
    setOlderSessions(categorizedSessions.olderSessions);
  }, [chatNames]);

  // Helper function to render chat names based on the category
  const renderChatNames = (chats, sessionTitle) => {
    if (chats.length === 0) return null;
    return (
      <div className="categoryContainer">
        <h4>{sessionTitle}</h4>
        {chats.map((chat) =>
          chat.name ? (
            <NavPrompt
              key={chat.id}
              chatName={chat.name}
              setChatName={setChatName}
              chatNames={chatNames}
              setChatNames={setChatNames}
              chatID={chat.id}
              setChatID={setChatID}
              setChatLog={setChatLog}
              setHasPrinted={setHasPrinted}
              isActive={activeId === chat.id}
              setActiveId={setActiveId}
              textAreaRef={textAreaRef}
              llmLabel={chat.llmLabel}
              llmId={chat.llmId}
              llmConfig={chat.llmConfig}
              setLlmLabel={setLlmLabel}
              setLlmId={setLlmId}
              setLlmConfig={setLlmConfig}
            />
          ) : null
        )}
      </div>
    );
  };

  return (
    <>
      <NewChat
        setChatName={setChatName}
        setChatID={setChatID}
        setChatLog={setChatLog}
        setShowMenu={setShowMenu}
        setActiveId={setActiveId}
        setLlmId={setLlmId}
        setLlmLabel={setLlmLabel}
        setLlmConfig={setLlmConfig}
        setForm={setForm}
        textAreaRef={textAreaRef}
      />
      <div className="navPromptWrapper">
        {renderChatNames(todaySessions, "Today")}
        {renderChatNames(last7DaysSessions, "Previous 7 Days")}
        {renderChatNames(last30DaysSessions, "Previous 30 Days")}
        {renderChatNames(olderSessions, "Older")}
      </div>
      <NavLinksContainer
        setChatLog={setChatLog}
        chatNames={chatNames}
        setChatNames={setChatNames}
        setCurrentUser={setCurrentUser}
        setChatID={setChatID}
        setChatName={setChatName}
        setActiveId={setActiveId}
        setLlmId={setLlmId}
        setLlmLabel={setLlmLabel}
        setLlmConfig={setLlmConfig}
        setForm={setForm}
        textAreaRef={textAreaRef}
      />
    </>
  );
};

export default NavContent;
