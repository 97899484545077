const TrashIcon = ({ fill="white", height=20, width=20 }) => {
    return (
        <svg
        viewBox="0 0 24 24"
        data-name="Flat Line"
        xmlns="http://www.w3.org/2000/svg"
        className="icon flat-line"
        stroke="#fff"
        width={width}
        height={height}
        fill={fill}
      >
        <path
          d="M5 8h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5V8Z"
          transform="rotate(90 12 14)"
          style={{
            fill: "none",
            strokeWidth: 2,
          }}
        />
        <path
          d="M16 7V4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3"
          style={{
            fill: "none",
            stroke:fill,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
          }}
        />
        <path
          data-name="primary"
          d="M10 11v6m4-6v6M4 7h16m-2 13V7H6v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1Z"
          style={{
            fill: "none",
            stroke: fill,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
          }}
        />
      </svg>
    );
}

export default TrashIcon; 