import React from "react";
import config from "../config";

const NewChat = ({
  setChatLog,
  setChatID,
  setShowMenu,
  setChatName,
  setActiveId,
  setLlmId,
  setLlmLabel,
  setLlmConfig,
  setForm,
  textAreaRef,
}) => {
  return (
    <div
      className="sideMenuButton"
      onClick={() => {
        setChatLog([]);
        setChatID("");
        setChatName("");
        setShowMenu(true);
        setActiveId("");
        setForm(null);
        setLlmId(config.DEFAULT_FUNCTION_ID);
        setLlmLabel(config.DEFAULT_FUNCTION_LABEL);
        setLlmConfig(config.DEFAULT_LLM_CONFIG);
        textAreaRef.current.focus();
      }}
    >
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
