export const FileUploaderIcon = ({ fill }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 6V11C7 11.5523 6.55229 12 6 12C5.44771 12 5 11.5523 5 11V6H2.41418C1.8619 6 1.41418 5.55229 1.41418 5C1.41418 4.73478 1.51956 4.48044 1.70709 4.29291L6 0L10.2929 4.29291C10.6834 4.68343 10.6834 5.31657 10.2929 5.70709C10.1054 5.89463 9.85103 6 9.58582 6H7ZM1 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H1C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14Z"
      fill="white"
    />
  </svg>
);
