import React, { useRef } from "react";
import { FileUploaderIcon } from "./icons/fileUploader";
import config from "../config"
import Home from "../pages/Home";

const FileUploader = ({ setForm, setInputPrompt }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    console.log("File uploaded");
    const selectedFile = event.target.files[0];

    // Reset file input if no file is selected
    if (!selectedFile) {
      fileInputRef.current.value = null;
      return;
    }
    // Make sure user is selecting only PDFs
    const fileExtension = selectedFile.name.split('.').pop();
    if (!config.SUPPORTED_FILE_TYPES.includes(`.${fileExtension}`)) {
      alert("Invalid file type. Please select a valid file type. " +
            "If the file type is not in full lower case, then change it to be. ")
      fileInputRef.current.value = null;
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    formData.append(
      "prompt",
      `Can you process my file: "${selectedFile.name}"?`,
    );

    setForm(formData);

    setInputPrompt(`Can you process my file: "${selectedFile.name}"?`);
    setTimeout(() => {
      Home.handleSubmit(event);
    }, 100);
  };

  return (
    <div
      onClick={handleClick}
      className="sideMenuButton"
      style={{ marginTop: "10px", width: "35%" }}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <span>
        <FileUploaderIcon />
      </span>
      Upload Reference PDF
    </div>
  );
};

export default FileUploader;
