import Rollbar from "rollbar";
import config from "../config";

// Set the environment and Rollbar access token
const accessToken = config.ROLLBAR_ACCESS_TOKEN;

// Function to get the current environment based on the hostname
const getEnvironment = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case "localhost":
      return "development";
    case "ai.development.bamboohr.net":
      return "dev_eks";
    case "ai.staging.bamboohr.net":
      return "staging";
    case "ai.bamboohr.net":
      return "production";
    default:
      console.error(
        `Rollbar not initialized properly. Unexpected hostname: ${hostname}`
      );
      return null;
  }
};

const environment = getEnvironment();

// Check if Rollbar should be enabled
const enabled =
  environment != null &&
  environment !== "development" &&
  environment !== "test";

// Payload for Rollbar configuration
const payload = {
  environment,
};

console.log("Initializing Rollbar with payload:", payload);

// Fields to redact before sending to Rollbar
const scrubFields = ["state", "code", "scope", "hd", "cookie"];

// Rollbar configuration options
const rollbarConfig = {
  accessToken: accessToken,
  enabled: enabled,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: payload,
  scrubFields: scrubFields,
};

// Create a Rollbar instance
const rollbarInstance = new Rollbar(rollbarConfig);

// Function to report errors to Rollbar
export const reportError = (message, error) => {
  if (enabled) {
    rollbarInstance.error(message, error);
  }
};
