import "./normal.css";
import "./App.css";
import Home from "./pages/Home";
import LoginForm from "./components/login/LoginForm";
import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import LoginCallbackHandler from "./components/login/LoginCallbackHandler";
import TermsAndConditions from "./components/login/TermsAndConditions";
import LoginErrorPage from "./components/login/LoginError";
import { checkAndShowPersistentToast } from "./utils/persistentToast";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null)
  const [errorCode, setErrorCode] = useState("")

  useEffect(() => {
    checkAndShowPersistentToast();
  },[]);

  const RequireAuth = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/home" />;
    } else if (currentUser && !currentUser["has-accepted-terms"]) {
      return <Navigate to="/terms-and-conditions" />;
    } else {
      return children;
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route
          index
          exact
          path="/"
          element={
            <RequireAuth>
              <Home currentUser={currentUser} setCurrentUser={setCurrentUser} />
            </RequireAuth>
          }
        />
        <Route exact path="/home" element={<LoginForm setErrorCode={setErrorCode} />} />
        <Route path="/login" element={<LoginCallbackHandler setErrorCode={setErrorCode} setCurrentUser={setCurrentUser} />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
        <Route path="/login-error" element={<LoginErrorPage errorCode={errorCode} />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
