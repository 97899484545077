import { useState } from "react";
import { styles } from "./styles";
import { ThumbsDown, CheckIcon } from "../icons";
import { XIcon } from "../../footerMessage.react/x-icon";

import config from "../../../config";
import api from "../../../services/api";

const ModalCheckbox = (props) => {
  const { checked, label, onChange } = props;

  return (
    <div style={styles.checkboxContainer}>
      <button
        style={{
          ...styles.checkbox,
          border: checked ? "0px" : "1px solid rgba(178, 178, 178)",
        }}
        onClick={() => onChange(label, !checked)}
      >
        {checked ? (
          <CheckIcon fill={"white"} backgroundFill={"#5A9917"} />
        ) : null}
      </button>
      <span className="checbox__visualCheckbox">{label}</span>
    </div>
  );
};

export const FeedbackModal = (props) => {
  const {
    closeModal,
    chatId,
    unhelpful,
    incorrect,
    harmful,
    comment,
    userSuggestedAnswer,
  } = props;
  const [feedbackData, setFeedbackData] = useState({
    "Potentially Harmful": harmful,
    "Not Entirely Correct": incorrect,
    "Less Helpful Than It Could Be": unhelpful,
    comment: comment,
    userSuggestedAnswer: userSuggestedAnswer,
  });

  const CHAT_FEEDBACK_ENDPOINT = config.VERSION_ENDPOINT + "chat/" + chatId;

  const updateFeedbackData = (newDataKey, newDataValue) => {
    setFeedbackData({ ...feedbackData, [newDataKey]: newDataValue });
  };

  const sendFeedback = async () => {
    api.put(CHAT_FEEDBACK_ENDPOINT, {
      rating: "negative",
      unhelpful: feedbackData["Less Helpful Than It Could Be"] || false,
      incorrect: feedbackData["Not Entirely Correct"] || false,
      harmful: feedbackData["Potentially Harmful"] || false,
      comment: feedbackData.comment || "",
      user_suggested_answer: feedbackData.userSuggestedAnswer || "",
    });
  };

  return (
    <div style={styles.popoverContainer}>
      <div style={styles.popoverContent}>
        <div style={styles.popoverHeader}>
          <div style={styles.thumbsDownContainer}>
            <ThumbsDown fill={"#FE4A22"} />
          </div>
          <strong style={styles.titleText}>
            Why did you choose this rating?
          </strong>
          <div style={styles.exitButtonContainer}>
            <button
              style={styles.exitButton}
              onClick={() => {
                closeModal();
              }}
            >
              <XIcon fill="#999" />
            </button>
          </div>
        </div>

        <ModalCheckbox
          label="Potentially Harmful"
          onChange={updateFeedbackData}
          checked={feedbackData["Potentially Harmful"]}
        />
        <ModalCheckbox
          label="Not Entirely Correct"
          onChange={updateFeedbackData}
          checked={feedbackData["Not Entirely Correct"]}
        />
        <ModalCheckbox
          label="Less Helpful Than It Could Be"
          onChange={updateFeedbackData}
          checked={feedbackData["Less Helpful Than It Could Be"]}
        />
        <textarea
          style={styles.textArea}
          placeholder="Provide additional detail or feedback"
          onChange={(event) =>
            setFeedbackData({
              ...feedbackData,
              comment: event.target.value,
            })
          }
        />
        <textarea
          style={styles.textArea}
          placeholder="What’s a complete example of an excellent answer to this question? (optional)"
          onChange={(event) =>
            setFeedbackData({
              ...feedbackData,
              userSuggestedAnswer: event.target.value,
            })
          }
        />
        <button
          style={styles.submitButton}
          onClick={() => {
            sendFeedback();
            closeModal();
          }}
        >
          <strong style={styles.buttonText}>Submit</strong>
        </button>
      </div>
    </div>
  );
};
