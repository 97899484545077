/**
 * Convenience module for loading all utilities at once.
 */

const googleOAuth2 = require('./google-oauth2');

let jsUtils = {
    googleOAuth2: googleOAuth2
};

module.exports = jsUtils;
