import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./DropdownPortal.css";

const DropdownPortal = ({ options, position, onClose, onClickOption }) => {
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, onClose]);


  return ReactDOM.createPortal(
    <div
      ref={dropdownRef}
      style={{
        position: "absolute",
        top: `${position.top + position.height}px`,
        left: `${position.left}px`,
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
        zIndex: 1000,
        padding: "4px"
      }}
    >
      {options.map((option) => (
        <div 
          key={option.value} onClick={(event) => {
          event.stopPropagation();
          onClickOption(option)}}
          className="dropdownOption" 
          style={option.style}
          >
          {option.icon}
          {option.label}
        </div>
      ))}
    </div>,
    document.body
  );
};

export default DropdownPortal;