export const styles = {
	sectionContainer: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'rgb(68, 70, 84)',
		padding: '12px 24px',
	},
	outerContainer: {
		width: 'min(100%, 752px)',
		padding: '16px 20px 20px 20px',
		backgroundColor: '#353540',
		borderRadius: 5,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	emoji: {
		fontSize: 30
	},
	textContainer: {
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		margin: '0px 8px 0px 16px',
		width: '100%',
	},
	textHeader: {
		textAlign: 'left',
		fontSize: 18,
	},
	textSubtitle: {
		textAlign: 'left',
		fontSize: 16,
	},
	xButton: {
		backgroundColor: 'transparent',
		height: 24,
		border: 0,
	}
}