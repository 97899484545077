import { useEffect, useState } from "react";
import config from "../config";
import Select from "react-select";
import api from "../services/api";
const MODES_ENDPOINT = config.VERSION_ENDPOINT + "modes";

export const LlmSelector = (props) => {
  const { setNewLlmValue, setLlmConfig, setModes } = props;
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultIndex, setDefaultIndex] = useState(0);

  const getLlmData = async () => {
    api.get(MODES_ENDPOINT).then((response) => {
      const newOptions = response.data.map((resp) => {
        return {
          label: resp.mode,
          value: resp["function_id"],
          llm_config: resp["llm_config"],
          sort_rank: resp["sort_rank"] || Infinity,
        };
      });

      newOptions.sort((a, b) => a.sort_rank - b.sort_rank);

      const newModes = response.data.reduce((accumulator, resp) => {
        accumulator[resp["function_id"]] = resp.mode;
        return accumulator;
      }, {});

      setOptions(newOptions);
      setModes(newModes);
      setIsLoading(false);
    }).catch((error) => {
      console.error("Error occurred:", error);
    });
  };

  useEffect(() => {
    if (options.length === 0) {
      getLlmData();
    }
  }, []);

  useEffect(() => {
    options.forEach((item, index) => {
      if (item.label === config.DEFAULT_FUNCTION_LABEL) {
        setDefaultIndex(index);
      }
    });

    if (options.length === 1) {
      setLlmConfig(options[0].llm_config);
      setNewLlmValue(options[0]);
    }
  }, [options]);

  return (
    <>
      {isLoading ? (
        <span>Loading....</span>
      ) : (
        <div style={{ minWidth: 250 }}>
          <Select
            options={options}
            defaultValue={options[defaultIndex]}
            onChange={(selectedItem) => {
              setNewLlmValue(selectedItem);
              setLlmConfig(selectedItem.llm_config);
            }}
            styles={{
              singleValue: (baseStyles) => ({
                ...baseStyles,
                color: "#d1d5db",
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: "#d1d5db",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "transparent",
                borderColor: "#d1d5db",
                color: "white",
                marginLeft: 8,
              }),
              option: (provided) => ({
                ...provided,
                color: "#d1d5db" /* Your desired font color */,
                backgroundColor:
                  "transparent" /* Your desired background color */,
                ":hover": {
                  color: "white",
                },
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                border: "1px solid #d1d5db",
                backgroundColor: "transparent",
                padding: 4,
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "transparent",
                color: "white",
              }),
              indicatorSeparator: () => ({
                visibility: "none",
              }),
              indicatorContainer: (baseStyles) => ({
                ...baseStyles,
                ":& svg": {
                  fill: "green",
                },
                ":hover": {
                  color: "cyan",
                },
              }),
            }}
          />
        </div>
      )}
    </>
  );
};
