export const Copy = ({ fill }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" >
		<path d="M11 3.75C11.825 3.75 12.5 4.425 12.5 5.25V10.5C12.5 11.325 11.825 12 11 12H5.75C4.925 12 4.25 11.325 4.25 10.5V5.25C4.25 4.425 4.925 3.75 5.75 3.75H11ZM11 10.5V5.25H5.75V10.5H11ZM8.75 2.25H7.25V1.5H2V6.75H2.75V8.25H2C1.175 8.25 0.5 7.575 0.5 6.75V1.5C0.5 0.675 1.175 0 2 0H7.25C8.075 0 8.75 0.675 8.75 1.5V2.25Z" fill={fill}/>
	</svg>
)

export const ThumbsUp = ({ fill }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" >
	<path d="M11.3 4.20002H6.72499L7.60817 1.86653C7.83437 1.26893 7.63337 0.585542 7.12698 0.228543C6.54858 -0.178856 5.76857 -0.0274549 5.36477 0.570144L2.91138 4.20002H2.89999V9.60001C2.89999 10.9254 3.97459 12 5.29999 12H9.31999C9.79399 12 10.2242 11.7204 10.4168 11.2872L12.3968 6.83234C12.4652 6.67874 12.5 6.51313 12.5 6.34513V5.40002C12.5 4.73702 11.963 4.20002 11.3 4.20002ZM1.1 12C0.768799 12 0.5 11.7312 0.5 11.4V4.80002C0.5 4.46882 0.768799 4.20002 1.1 4.20002C1.4312 4.20002 1.7 4.46882 1.7 4.80002V11.4C1.7 11.7312 1.4312 12 1.1 12Z" fill={fill}/>
</svg>
)

export const ThumbsDown = ({ fill }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" >
	<path d="M7.69999 0H3.68C3.206 0 2.7758 0.279 2.5832 0.712799L0.6032 5.1678C0.5348 5.3214 0.5 5.487 0.5 5.655V6.6C0.5 7.2624 1.037 7.79999 1.7 7.79999H6.275L5.3918 10.1334C5.1656 10.731 5.3666 11.4144 5.873 11.7714C6.4514 12.1788 7.2314 12.0276 7.63519 11.43L10.0886 7.79999H10.1V2.4C10.1 1.0746 9.02539 0 7.69999 0ZM11.9 7.79999C11.5688 7.79999 11.3 7.5312 11.3 7.2V0.6C11.3 0.2688 11.5688 0 11.9 0C12.2312 0 12.5 0.2688 12.5 0.6V7.2C12.5 7.5312 12.2312 7.79999 11.9 7.79999Z" fill={fill}/>
</svg>
)

export const CheckIcon = ({ backgroundFill, fill }) => (
	// <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
	// 	<path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
	// </svg>
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<rect width="20" height="20" rx="1.66667" fill={backgroundFill} />
		<path d="M14.2483 6.80823L10.0817 14.0251L6.16821 10.8034" stroke={fill} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)
