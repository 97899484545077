import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { googleOAuth2 } from "js-utils";
import config from "../../config";
import api from "../../services/api";

const LoginCallbackHandler = ({ setErrorCode, setCurrentUser }) => {
  let location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const state = urlParams.get("state");
  const LOGIN_ENDPOINT = config.VERSION_ENDPOINT + "auth/login";
  const LOGIN_DEV_ENDPOINT = config.VERSION_ENDPOINT + "user";
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      let response;
      if (config.DEVELOPMENT) {
        console.log("Logging in development user...");
        response = await api.post(LOGIN_DEV_ENDPOINT, {
          state: state,
          "user-properties": config.USER_PROPERTIES,
        });
      } else {
        console.log("Logging in normal user...");
        response = await googleOAuth2.login(
          LOGIN_ENDPOINT,
          state,
          config.USER_PROPERTIES,
          false
        );
      }
      if (response.status === 200) {
        setCurrentUser(config.DEVELOPMENT ? response.data : response.userinfo);
        navigate("/");
      } else {
        console.error("Response Status:", response.status);
        setErrorCode(response.status);
        navigate("/login-error");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setErrorCode(error.code);
      navigate("/login-error");
    }
  };

  handleLogin();

  return <div>Processing authentication...</div>;
};

export default LoginCallbackHandler;
