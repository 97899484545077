export const styles = {
	botMessageMainContainer: {
		width: '100%',
		backgroundColor: '#444654',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	chatPromptMainContainer: {
		width: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	botMessageWrapper: {
		width: '800px',
		padding: '24px',
		columnGap: '25px',
		display: 'flex',
		flexDirection: 'column',
	},
	chatPromptWrapper: {
		width: '800px',
		padding: '24px',
		columnGap: '25px',
		display: 'flex',
		flexDirection: 'column',
	},
	botMessage: {
		margin: 0,
		padding: 0,
		textIndent: 0,
		textAlign: 'left',
	},
	messageHeader: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
		gap: 12,
	},
	chatHeader: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-start',
		gap: 12,
	},
	nameContainer: {
		flexGrow: 2,
		textAlign:'left'
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 6,
	},
	iconButton: {
		backgroundColor: 'transparent',
		borderWidth: 0,
		'&: svg': {
			fill: 'white',
		},
	},
	copiedMessage: {
		marginRight: 4,
	},
	avatarImage: {
		height: 24,
		width: 24,
		position: "relative",
	},
	chatPrompt: {
		marginTop: 12,
		textAlign: 'left',
	}
}