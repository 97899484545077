const DotsIcon = ({ fill="none", stroke="white", width=20, height=20 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={fill}>
        <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" stroke={stroke} stroke-width="2"/>
        <path d="M8 12H8.01" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 12H12.01" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 12H16.01" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default DotsIcon;