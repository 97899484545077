
export const styles = {
	popoverContainer: {
		position: 'relative',
		display: 'inline-block',
	},

	popoverContent: {
		background: '#383540',
		border: '1px solid rgba(178, 178, 178)',
		borderRadius: 5,
		position: 'absolute',
		top: 6,
		left: -359,
		padding: 24,
		width: '359px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		zIndex: 1,

		// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
		'&::before': {
			content: '',
			position: 'absolute',
			top: '-10px',
			left: '150%',
			transform: 'translateX(-150%)',
			borderWidth: '10px',
			borderStyle: 'solid',
			// borderColor: 'transparent transparent #ccc transparent',
		},
	},
	popoverHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 12,
		width: '100%',
		marginBottom: 16,
	},
	exitButtonContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: 30,
	},
	exitButton: {
		background: 'transparent',
		width: 22,
		height: 16,
		borderWidth: 0,
	},
	thumbsDownContainer: {
		border: '1px solid #FE4A22',
		borderRadius: 5,
		padding: '2px 8px',
		background: 'rgba(254, 74, 34, 0.10)'
	},
	titleText: {
		width: '100%',
		textAlign: 'left',
	},
	checkboxContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 4,
		alignItems: 'center',
	},
	checkbox: {
		height: 20,
		width: 20,
		borderRadius: 2,
		backgroundColor: '#2E2A36',
		marginRight: 8,
		padding: 0,
	},
	textArea: {
		height: 92,
		width: 275,
		borderRadius: 5,
		marginTop: 8,
		resize: 'none',
		backgroundColor: 'transparent',
		color: 'white',
		padding: '8px 12px',
		marginBottom: 12,
	},
	submitButton: {
		padding: '6px 16px',
		backgroundColor: '#5A9917',
		border: '0px solid #ffffff',
		borderRadius: 5,
	},
	buttonText: {
		color:' white',
	}

	// .popover-content p {
	// 	margin: 0,
	// },
}
